.main-our-products {
  display: flex;
  align-items: start;
  margin: 0 100px 78px 106px;

  .filters {
    min-width: 238px;
    margin-right: 62px;

    .filter-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      color: #FFFFFF;
      padding: 14px 20px;
      background-color: #3664F6;
      border: none;
      border-radius: 6px;
      margin-bottom: 38px;
      cursor: pointer;
    }

    .price-selection {
      margin-bottom: 45px;
    }

    .colors {
      margin-bottom: 35px;
    }

    .filter-by {
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 40px;
      background-color: #3664F6;
      width: 100%;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
      padding: 15px 27px;
      margin-bottom: 10px;
      border: none;
      cursor: pointer;
      margin-top: 50px;
      border-radius: 6px;

      img {
        margin-right: 11px;
      }
    }

    .reset-filter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-height: 40px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      background-color: #E0EBFF;
      color: #3664F6;
      padding: 15px 27px;
      border: none;
      border-radius: 6px;
      cursor: pointer;
    }
  }

  .category-selection {

    .checkbox {
      margin-bottom: 10px;
    }
  }

  .our-products {
    width: 100%;

    .header {
      display: flex;
      align-items: start;
      justify-content: space-between;
      margin-bottom: 25px;

      .title {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 24px;
        color: #29263A;
      }

      .view {
        display: flex;
        align-items: center;

        .grid-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 34px;
          height: 34px;
          padding: 8px;
          background-color: #3664F6;
          margin-right: 10px;
          border: none;
          cursor: pointer;
          border-radius: 6px;
        }

        .list-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 34px;
          height: 34px;
          padding: 8px;
          border: none;
          cursor: pointer;
          border-radius: 6px;
          background-color: transparent;
        }
      }
    }

    .horizontal-line {
      width: 100%;
      border-bottom: 1px solid #E2E5EB;
      margin: 25px 0 30px 0;
    }

    .products-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      .controls-second {
        display: flex;
        align-items: center;
        right: 3px;
        top: 3px;
        border: 1px solid #E2E5EB;
        border-radius: 6px;
        padding: 2px;

        .sort-by {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 90px;
          height: 34px;
          background: #F5F7FB;
          border-radius: 6px;
          margin-right: 19px;
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #ACADB9;
        }

        .dropdown {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 6px;
          margin-right: 2px;
          cursor: pointer;

          .name {
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #29263A;
            margin-right: 60px;
          }

          img {
            transform: rotate(180deg);
            margin-right: 12px;
          }
        }
      }

      .products-input {
        width: 560px;
        height: 40px;
        background-color: grey;
      }

      .products-sort {
        width: 240px;
        height: 40px;
        background-color: grey;
      }
    }

    .product-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .product-card {
        margin-bottom: 31px;
      }
    }

    .see-more {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 200px;
      max-height: 40px;
      margin: 10px auto 0;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      background-color: #E0EBFF;
      color: #3664F6;
      padding: 15px 27px;
      border: none;
      border-radius: 6px;
      cursor: pointer;

      img {
        margin-right: 13px;
      }
    }
  }

  .selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;

    .arrow-down {
      transform: rotate(180deg);
    }

    p {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #29263A;
    }

    img {
      cursor: pointer;
    }
  }

  .checkbox {
    margin-bottom: 10px;
  }

  .horizontal-line {
    width: 100%;
    border-bottom: 1px solid #E2E5EB;
    margin: 25px 0 30px 0;
  }
}